<template>
  <div id="app" v-cloak>
    <loading></loading>
    <layout-header></layout-header>
    <layout-body></layout-body>
    <layout-footer></layout-footer>
  </div>
</template>
<script>
import layoutHeader from '@/components/layout/layout-header'
import layoutBody from '@/components/layout/layout-body'
import layoutFooter from '@/components/layout/layout-footer'
import Loading from '@/components/loading'
import Cookies from 'js-cookie'
import {h} from "vue";
import {fetchSingleUserInfoByUserName} from "@/api/userInfo";

export default {
  name: "app",
  components: {
    layoutHeader,
    layoutBody,
    layoutFooter,
    Loading
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo; // 使用computed属性来访问userInfo
    },
  },
  data() {
    return {
      queryForm: {
        userName: ""
      }
    }
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    starLoading() {
      // 创建一个 loading 实例并赋值给 loading 变量
      let loading = this.$loading({
        text: "加载中", // 设置 loading 文本为 "加载中"
        spinner: "el-icon-loading", // 使用 Element UI 提供的加载图标
        background: "rgba(0, 0, 0, 0.7)", // 设置 loading 遮罩层背景色为半透明黑色
        target: document.querySelector("body"), // 将 loading 遮罩层挂载到页面 body 元素上
      });
      // 返回一个包含关闭 loading 遮罩层方法的对象
      return {
        // 方法用于关闭 loading 遮罩层
        closeLoading: () => {
          loading.close(); // 调用 loading 实例的 close 方法关闭遮罩层
        }
      };
    },
    getUserInfo() {
      const userToken = Cookies.get('userToken')
      if (!userToken) {
        this.$msgbox({
          title: 'Opps!',
          message: h('p', null, [
            h('span', null, '尊敬的用户您好，您还未'),
            h('a', {href: '/login', style: 'color: teal'}, '登录~~')
          ])
        })
        this.$router.push('/login')
      } else {
        let userInfo1 = this.$store.getters.userInfo;
        if (!userInfo1) {
          this.queryForm.userName = userToken
          fetchSingleUserInfoByUserName(this.queryForm).then(res => {
            if (res.code === 200) {
              // 假设你已经在某个Vue组件中，可以通过this.$store访问store
              this.$store.commit('SET_USER_INFO', res.data);
            }
          })
        }
      }
    }
  }
}
</script>
<style lang="less">
#app {
  font-size: 17px;
  color: #6f6f6f;
}
</style>
