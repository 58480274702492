import request from '@/utils/request'

export function getUniversity(data) {
    return request({
        // url: '/post/list',
        // method: 'get',
        url: '/universityInfoServ/list',
        method: 'post',
        data: data
    })
}

export function addArticle(data) {
    return request({
        // url: '/post/list',
        // method: 'get',
        url: '/articleContentInfoServ/addArticle',
        method: 'post',
        data: data
    })
}

export function deleteArticle(data) {
    return request({
        // url: '/post/list',
        // method: 'get',
        url: '/articleContentInfoServ/deleteArticle',
        method: 'post',
        data: data
    })
}

export function updateArticle(data) {
    return request({
        // url: '/post/list',
        // method: 'get',
        url: '/articleContentInfoServ/updateArticle',
        method: 'post',
        data: data
    })
}

export function fetchOperations(data) {
    return request({
        // url: '/post/list',
        // method: 'get',
        url: '/articleOperationInfoServ/list',
        method: 'post',
        data: data
    })
}

export function fetchList(data) {
    return request({
        // url: '/post/list',
        // method: 'get',
        url: '/articleContentInfoServ/list',
        method: 'post',
        data: data
    })
}

export function login(data) {
    return request({
        // url: '/post/list',
        // method: 'get',
        url: '/urUserInfoServ/login',
        method: 'post',
        data: data
    })
}

export function updateUserInfo(data) {
    return request({
        // url: '/post/list',
        // method: 'get',
        url: '/urUserInfoServ/update',
        method: 'post',
        data: data
    })
}

export function register(data) {
    return request({
        // url: '/post/list',
        // method: 'get',
        url: '/urUserInfoServ/register',
        method: 'post',
        data: data
    })
}

export function fetchFocus(data) {
    return request({
        url: '/articleContentInfoServ/list',
        method: 'post',
        data: data
    })
}

export function fetchCategory(data) {
    return request({
        // url: '/category',
        url: '/aricleCateGoryDictServ/list',
        method: 'post',
        data: data
    })
}

export function fetchFriend() {
    return request({
        url: '/friend',
        method: 'get',
        params: {}
    })
}

export function fetchSocial() {
    return request({
        url: '/social',
        method: 'get',
        params: {}
    });
}

export function fetchSiteInfo(data) {
    return request({
        // url: '/site',
        // method: 'get',
        url: '/webSiteInfoServ/list',
        method: 'get',
        params: {data}
    })
}


export function uploadImg(data) {
    return request({
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        url: '/uploadServ/uploadImg',
        method: 'post',
        data: data
    })
}

export function fetchUserInfo(data) {
    return request({
        url: '/urUserInfoServ/list',
        method: 'post',
        data: data
    })
}


export function fetchFriendsInfo(data) {
    return request({
        url: '/urUserInfoServ/friendsList',
        method: 'post',
        data: data
    })
}

export function doLike(data) {
    return request({
        // url: '/site',
        // method: 'get',
        url: '/articleContentInfoServ/doLike',
        method: 'post',
        data: data
    })
}

export function addComment(data) {
    return request({
        // url: '/site',
        // method: 'get',
        url: '/articleCommentInfoServ/addComment',
        method: 'post',
        data: data
    })
}

export function fetchComment(data) {
    return request({
        // url: '/comment',
        // method: 'get',
        url: '/articleCommentInfoServ/getByArticleId',
        method: 'post',
        data: data
    })
}
export function fetchAddressByIp(data) {
    return request({
        // url: '/comment',
        // method: 'get',
        url: '/apiServ/getAddressByIp',
        method: 'post',
        data: data
    })
}
