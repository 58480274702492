import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import VueQuillEditor from 'vue-quill-editor'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/style.less'
import './assets/font/iconfont.css'
import 'github-markdown-css/github-markdown.css' // 引入markdown.css
//引用vue-quill-editor组件和样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import watermark from './utils/watermark'
import {parseTime} from './utils'
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css' //样式
Vue.use(ElementUI)
Vue.use(VueQuillEditor)// 引入水印文件地址

Vue.prototype.$watermark = watermark

require('./Mock')


Vue.directive('highlight', function (el) {
    let blocks = el.querySelectorAll('pre code');
    blocks.forEach((block) => {
        hljs.highlightBlock(block)
    })
})

Vue.config.productionTip = false
Vue.filter('parseTime', (v) => parseTime(v, '{y}-{m}-{d}'))
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
