let watermark = {};
let setWatermark = (text, sourceBody) => {
    //let id = 'watermark_fjq_' + parseInt(Math.random() * 100000);
    let id = 'watermark_fjq_123456789';
    if (document.getElementById(id) !== null) {
        document.body.removeChild(document.getElementById(id));
    }
    //水印图片
    let can = document.createElement('canvas');
    can.width = 300; // 单个水印大小
    can.height = 180; // 单个水印大小
    let cans = can.getContext('2d');
    cans.rotate((-20 * Math.PI) / 180);
    cans.font = '11px Vedana';
    cans.fillStyle = 'rgba(0,0,0,0.34)'; //水印颜色
    cans.textAlign = 'left';
    cans.textBaseline = 'Middle';
    cans.fillText(text, can.width / 20, can.height);
    //设置插入div样式
    let water_div = document.createElement('div');
    water_div.id = id;
    water_div.style.pointerEvents = 'none';
    water_div.style.overflow = 'hidden';
    water_div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat';
    if (sourceBody) {//是否在指定页面加水印
        sourceBody.style.position = 'relative';
        water_div.style.width = '100%';
        water_div.style.height = '100%';
        water_div.style.position = 'absolute';
        water_div.style.top = '0';
        water_div.style.left = '0';
        sourceBody.appendChild(water_div);
    } else {//所有页面加水印
        water_div.style.top = '3px';
        water_div.style.left = '200px';
        water_div.style.position = 'fixed';
        water_div.style.zIndex = '9999';
        water_div.style.width = document.documentElement.offsetWidth + 'px';
        water_div.style.height = document.documentElement.offsetHeight + 'px';
        document.body.appendChild(water_div);
    }
    return id;
};

/**
 *  该方法只允许调用一次
 *  @param:
 *  @text == 水印内容
 *  @sourceBody == 水印添加在哪里，不传就是body
 * */
watermark.set = (text, sourceBody) => {
    setTimeout(() => {
        setWatermark(text, sourceBody);
    }, 300); //延迟加载
};
//移除水印
watermark.remove = (text, sourceBody) => {
    let id = 'watermark_fjq_123456789';
    if (document.getElementById(id) !== null) {
        document.body.removeChild(document.getElementById(id));
    }
}
export default watermark;


