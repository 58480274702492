import request from '@/utils/request'

export function fetchSingleUserInfoByUserName(data) {
    return request({
        url: '/urUserInfoServ/getSingleUserInfoByUserName',
        method: 'post',
        data: data
    })
}

export function fetchUserInfo(data) {
    return request({
        url: '/urUserInfoServ/list',
        method: 'post',
        data: data
    })
}

export function updateUserInfo(data) {
    return request({
        url: '/urUserInfoServ/update',
        method: 'post',
        data: data
    })
}